<template>
  <div class="row">
    <va-modal
      v-model="editModelModal"
      size="large"
      :hide-default-actions="true"
      :title="'Editing Model: ' + modalData.name"
    >
      <div class="flex xs12">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs
                grow
                v-model="tabValue"
                style="width: 100%;"
              >
                <va-tab
                  v-for="title in tabTitles.slice(0,3)"
                  :key="title"
                >
                  {{ title }}
                </va-tab>
              </va-tabs>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal app-layout__main"
        v-if="modalData.name"
      >
        <div v-if="tabValue === 0">
          <div class="row my-4">
            <div class="flex xs4">
              Name:
            </div>
            <div class="flex xs8">
              <va-input
                v-model="modalData.name"
                placeholder="model name"
              />
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Patient Name:
            </div>
            <div class="flex xs8">
              <va-input
                v-model="modalData.patientName"
                placeholder="Patient Name (Jack)"
              />
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Script:
            </div>
            <div class="flex xs8">
              <va-input
                v-model="modalData.script"
                placeholder="script key"
              />
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Group:
            </div>
            <div class="flex xs8">
              <va-input
                v-model="modalData.group"
                placeholder="Group Name"
              />
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Patient Age:
            </div>
            <div class="flex xs8">
              <va-input
                v-model="modalData.Age"
                placeholder="Group Name"
              />
            </div>
          </div>
          <div
            v-if="false"
            class="row my-4"
          >
            <div class="flex xs4">
              Evaluator Name:
            </div>
            <div class="flex xs8">
              <va-input
                v-model="modalData.evaluatorName"
                placeholder="Evaluator Name"
              />
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Patient Room:
            </div>
            <div class="flex xs8">
              <va-select
                :label="'Select Patient Room'"
                v-model="modalData.room"
                :options="rooms"
              />
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Bed Position:
            </div>
            <div class="flex xs8">
              <va-select
                :label="'Select Bed Position'"
                v-model="modalData.bedposition"
                :options="bedPositions"
              />
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Animator Controller:
            </div>
            <div class="flex xs8">
              <va-input
                v-model="modalData.animatorController"
                placeholder="Animator Controller"
              />
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Animations:
            </div>
            <div class="flex xs8">
              <va-collapse>
                <span slot="header">Show Animations</span>
                <ul slot="body">
                  <li
                    v-for="(anim,index) in modalData.animation"
                    :key="index"
                  >
                    {{ anim }}
                  </li>
                </ul>
              </va-collapse>
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Audio:
            </div>
            <div class="flex xs8">
              <va-collapse>
                <span slot="header">Show Audio</span>
                <ul slot="body">
                  <li
                    v-for="(audio,index) in modalData.audio"
                    :key="index"
                  >
                    {{ audio }}
                  </li>
                </ul>
              </va-collapse>
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Evaluator Animations:
            </div>
            <div class="flex xs8">
              <va-collapse>
                <span slot="header">Show Evaluator Animations</span>
                <ul slot="body">
                  <li
                    v-for="(anim,index) in modalData.evaluatorAnimation"
                    :key="index"
                  >
                    {{ anim }}
                  </li>
                </ul>
              </va-collapse>
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Months Audio:
            </div>
            <div class="flex xs8">
              <va-collapse>
                <span slot="header">Show Months Audio</span>
                <ul slot="body">
                  <li
                    v-for="(audio,index) in modalData.monthsAudio"
                    :key="index"
                  >
                    {{ audio }}
                  </li>
                </ul>
              </va-collapse>
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Month Flag:
            </div>
            <div class="flex sm8">
              <va-toggle
                v-model="modalData.monthFlag"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="flex xs4">
              Avatar Image:
            </div>
            <div class="flex sm8">
              <va-avatar
                v-if="modalData.avatarImage.image"
                class="ml-2"
              >
                <img
                  :src="modalData.avatarImage.image"
                >
              </va-avatar>
              <span v-else>No Avatar Image uploaded</span>
            </div>
          </div>
          <div class="row">
            <va-file-upload
              class="flex xs12"
              dropzone
              v-model="uploadedAvatarImage"
              @input="uploadAvatarImage"
            />
          </div>
        </div>

        <div
          v-if="tabValue === 1"
          class="flex xs12"
        >
          <div class="flex xs12">
            <va-card
              title="Rhythm Image"
            >
              <div class="row">
                <img
                  class="flex xs12 my-4"
                  v-if="modalData.ekg.image"
                  :src="modalData.ekg.image"
                >

                <span v-else>No EKG Image uploaded</span>
              </div>
              <div class="row">
                <va-file-upload
                  class="flex xs12"
                  dropzone
                  v-model="uploadedECG"
                  @input="uploadECG"
                />
              </div>
            </va-card>
            <va-card
              title="O₂ Image"
            >
              <div class="row">
                <img
                  class="flex xs12 my-4"
                  v-if="modalData.ekg.oxygenImage"
                  :src="modalData.ekg.oxygenImage"
                >
                <span v-else>No O<sub>2</sub> Image uploaded</span>
              </div>
              <div class="row">
                <va-file-upload
                  class="flex xs12"
                  dropzone
                  v-model="uploadedO2"
                  @input="uploadOxygen"
                />
              </div>
            </va-card>
            <va-card
              title="RR Image"
            >
              <div class="row">
                <img
                  class="flex xs12 my-4"
                  v-if="modalData.ekg.rrImage"
                  :src="modalData.ekg.rrImage"
                >

                <span v-else>No RR Image uploaded</span>
              </div>
              <div class="row">
                <va-file-upload
                  class="flex xs12"
                  dropzone
                  v-model="uploadedRR"
                  @input="uploadRR"
                />
              </div>
            </va-card>
            <ul>
              <li class="mb-4">
                <div
                  class="title mb-3"
                  :style="{color: this.$themes.primary}"
                >
                  Heart Rate
                </div>

                <va-slider
                  range
                  icon="fa fa-heartbeat"
                  value-visible
                  :min="40"
                  :max="120"
                  v-model="modalData.ekg.hr"
                />
              </li>
              <li class="mb-4">
                <div
                  class="title mb-3"
                  :style="{color: this.$themes.primary}"
                >
                  mmHg
                </div>

                <va-slider
                  class="mb-2"
                  range
                  label="Systolic (top)"
                  value-visible
                  :min="100"
                  :max="240"
                  v-model="modalData.ekg.mmHg.systolic"
                />
                <va-slider
                  range
                  label="Diastolic (bottom)"
                  value-visible
                  :min="60"
                  :max="120"
                  v-model="modalData.ekg.mmHg.diastolic"
                />
              </li>
              <li class="mb-4">
                <div
                  class="title mb-3"
                  :style="{color: this.$themes.primary}"
                >
                  O<sub>2</sub>
                </div>

                <va-slider
                  range
                  icon="fa fa-tree"
                  value-visible
                  :min="90"
                  :max="100"
                  v-model="modalData.ekg.o2"
                />
              </li>
              <li class="mb-4">
                <div
                  class="title mb-3"
                  :style="{color: this.$themes.primary}"
                >
                  RR
                </div>

                <va-slider
                  range
                  icon="entypo entypo-flow-line"
                  value-visible
                  :min="10"
                  :max="25"
                  v-model="modalData.ekg.rr"
                />
              </li>
              <li class="mb-4">
                <div
                  class="title mb-3"
                  :style="{color: this.$themes.primary}"
                >
                  Temperature
                </div>
                <va-slider
                  range
                  icon="fa fa-thermometer-3"
                  value-visible
                  :min="this.temp.min"
                  :max="this.temp.max"
                  :step="this.temp.step"
                   v-model="modalData.ekg.temp"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        v-if="tabValue === 2"
      >
        <div class="flex">
          <div class="row my-4">
            <div class="flex xs4">
              Which arm question (5a/5b) goes first?
            </div>
            <div class="flex xs8">
              <va-button-toggle
                @input="changeArm"
                v-model="modalData.leftOrRightArm"
                :options="armOptions"
              />
            </div>
          </div>
          <div class="row my-4">
            <div class="flex xs4">
              Which leg question (6a/6b) goes first?
            </div>
            <div class="flex xs8">
              <va-button-toggle
                @input="changeLeg"
                v-model="modalData.leftOrRightLeg"
                :options="legOptions"
              />
            </div>
          </div>
        </div>

        <div>
          <div class="flex md12">
              <va-card :title="'Exam Questions'">
                <va-data-table
                  :handle-data-update="false"
                  :fields="ExamQuestions"
                  :data="FormatExamQuestionData(modalData, translationData)"
                  :per-page="5"
                   hoverable
                >
                  <template slot="correctAnswer" slot-scope="props" >
                    <p style="cursor: pointer;" :title="props.rowData.fullCorrectAnswer">{{ props.rowData.correctAnswer }}</p>
                  </template>
                  <template
                    slot="actions"
                    slot-scope="props"
                  >
                      <va-button
                        small
                        @click="EditCurrentAnswerData(props.rowData, modalData)"
                      >
                      View/Update 
                    </va-button>
                  </template>
                </va-data-table>
              </va-card>
            </div>
        </div>
      </div>
     <va-modal
      v-model="editCurrentQuestionModal"
      size="large"
      :hide-default-actions="true"
      :title="'Editing Model Blueprint: '"
    > 
       <div class="flex xs12"  v-if="editCurrentQuestionModal">
        <div style="max-height: 400px; overflow-y: auto;">
              <va-card
                :title="translationData[this.currentBlueprint.question]"
                class="p-3"
              >
                <section>
                  <strong>Question:</strong>
                  <p>{{ this.currentBlueprint.Name }}</p>
                  <strong>Correct Answer:</strong>
                  <div>
                    <va-select
                      style="max-width: 100%;"
                      v-model="storedValues.correctAnswerIndex"
                      :options="filterChoices(this.currentBlueprint)"
                      key-by="id"
                      text-by="text"
                    />
                  </div>
                </section>
                <!-- ========= -->
                <section class="pb-2">
                  <strong>Responses:</strong>
                  <p>Key: <b>{{ this.currentBlueprint.answers[0] }}</b></p> 
                  <ul>
                    <div>
                        <click-to-edit
                          @input="updatedField(this.currentBlueprint, this.currentBlueprint.answers[0],...arguments)"
                          :value="translationData[this.currentBlueprint.answers[0]]"
                        />
                    </div>
                  </ul>
                  <p>Key: <b>{{ this.currentBlueprint.answers[1] }}</b></p> 
                  <ul>
                    <div>
                        <click-to-edit
                          @input="updatedField(this.currentBlueprint, this.currentBlueprint.answers[1],...arguments)"
                          :value="translationData[this.currentBlueprint.answers[1]]"
                        />
                    </div>
                  </ul>
                </section>
                <!-- ========= -->
                <section class="pb-2">
                  <strong>Choices:</strong>
                  <div v-for="choice in this.currentBlueprint.choices">
                    <p>{{ translationData[choice] }}</p> 
                  </div>
                </section>
                <!-- ========= -->
                <section class="pb-2">
                  <strong>Index:</strong>
                  <p>{{ this.currentBlueprint.index }}</p>
                </section>
                <!-- ========= -->
                <section class="pb-2">
                  <strong>Prop(s):</strong>
                  <va-select
                      :label="'Select Prop'"
                      v-model="storedValues.currentPropData"
                      multiple
                      :options="modelProps"
                   />
                </section>
              </va-card>
            </div>
      </div> 
      <div :slot="'actions'">
        <template>
          <va-button
            @click="storeQuestionChanges()"
            color="#4040ff"
          >
            Store 
          </va-button>
          <va-button
            @click="editCurrentQuestionModal = false"
            color="#cccccc"
          >
            Close
          </va-button>
        </template>
      </div>
    </va-modal>










      <div :slot="'actions'">
        <template>
          <va-button
            @click="editModelModal = false"
            color="#cccccc"
          >
            Cancel
          </va-button>
          <va-button
            @click="saveModel"
            color="#85bb65"
          >
            Save Model
          </va-button>
          <va-button
            @click="deleteData"

            color="#d20000"
          >
            Delete
          </va-button>
          <va-button
             @click="GenerateCollectionKeys()"
             :style="{ 'float': 'right' }"
              v-show="!this.modalData.HaveIl8nUpdated"
             color="#2D86EC"
           >
            Create Keys
          </va-button>
        </template>
      </div>
    </va-modal>
    <div class="flex md12">
      <va-card :title="'Model List'">
        <va-data-table
          :fields="fields"
          :data="models"
          no-pagination
          hoverable
        >
          <template
            slot="actions"
            slot-scope="props"
          >
            <va-button
              flat
              small
              @click="editModelModal = true; modalData = props.rowData"
            >
              Edit
            </va-button>
          </template>
        </va-data-table>
      </va-card>
      <div v-if="parsedModel == null">
        <va-file-upload
          dropzone
          v-model="uploadedModel"
          @input="uploadModel"
        />
      </div>
      <div v-else>
        <div class="flex md6 xs6">
          <va-card title="Parsed model">
            <div class="row my-4">
              <div class="flex xs4">
                Name:
              </div>
              <div class="flex xs8">
                {{ parsedModel.name }}
              </div>
            </div>
            <div class="row my-4">
              <div class="flex xs4">
                Group:
              </div>
              <div class="flex xs8">
                {{ parsedModel.group }}
              </div>
            </div>
            <div class="row my-4">
              <div class="flex xs4">
                Patient Age:
              </div>
              <div class="flex xs8">
                {{ parsedModel.Age }}
              </div>
            </div>
            <div class="row my-4">
              <div class="flex xs4">
                Evaluator Name:
              </div>
              <div class="flex xs8">
                {{ parsedModel.evaluatorName ? parsedModel.evaluatorName : 'None Specified' }}
              </div>
            </div>
            <div class="row my-4">
              <div class="flex xs4">
                Bed Position:
              </div>
              <div class="flex xs8">
                {{ parsedModel.bedPosition ? parsedModel.bedPosition : 'None Specified' }}
              </div>
            </div>
            <div class="row my-4">
              <div class="flex xs4">
                Animator Controller:
              </div>
              <div class="flex xs8">
                {{ parsedModel.animatorController ? parsedModel.animatorController : 'None Specified' }}
              </div>
            </div>
            <div class="row my-4">
              <div class="flex xs4">
                Animations:
              </div>
              <div class="flex xs8">
                <ul>
                  <li
                    v-for="(anim,index) in parsedModel.animation"
                    :key="index"
                  >
                    {{ anim }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row my-4">
              <div class="flex xs4">
                Audio:
              </div>
              <div class="flex xs8">
                <ul>
                  <li
                    v-for="(audio,index) in parsedModel.audio"
                    :key="index"
                  >
                    {{ audio }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row my-4">
              <div class="flex xs4">
                Evaluator Animations:
              </div>
              <div class="flex xs8">
                <ul>
                  <li
                    v-for="(anim,index) in parsedModel.evaluatorAnimation"
                    :key="index"
                  >
                    {{ anim }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="row my-4">
              <va-button
                :disabled="uploadingNewModel"
                @click="createModel()"
              >
                Create Model
                <svg
                  v-if="!uploadingNewModel"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>

                <Orbit-Spinner
                  :color="'white'"
                  v-if="uploadingNewModel"
                />
              </va-button>
            </div>
          </va-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import { SemipolarSpinner } from 'epic-spinners'
import firebase from 'firebase';
import {OrbitSpinner} from 'epic-spinners';
import moment from 'moment';
import emptyCourse from '../../data/emptyCourse.json';
import modelProps from '../../data/props';
import bedPositions from '../../data/bedPositions';
import rooms from '../../data/rooms';
import ClickToEdit from '../ClickToEdit.vue';
import { Swal } from 'sweetalert2/dist/sweetalert2';
import LogCreator from '../../class/LogCreator';
//import va-slider-dev from ./slider/va-slider-dev.vue


// const db = firebase.firestore()
export default {
  name: 'Admin',
  data: function() {
    return {
      tabValue: 0,
      armOptions: [{label: 'Left Arm', value: 'left'}, {label: 'Right Arm', value: 'right'}],
      legOptions: [{label: 'Left Leg', value: 'left'}, {label: 'Right Leg', value: 'right'}],
      tabTitles: ['Model Data', 'EKG', 'Exam'],
      temp: {
        min: 94,
        max: 104,
        step: 0.1
      },
      currentAnswer: {},
      currentChoices: [],
      lastProcessedLesson: null,
      emptyCourse: emptyCourse,
      uploadedModel: [],
      uploadedECG: [],
      uploadedAvatarImage: [],
      uploadedO2: [],
      uploadedRR: [],
      currentBlueprint: {},
      currentRowData: {},
      parsedModel: null,
      loading: false,
      translationData: {},
      uploadingNewModel: false,
      rooms: rooms,
      bedPositions: bedPositions,
      modelProps: modelProps,
      exams: [],
      editModelModal: false,
      editCurrentQuestionModal: false,
      modalData: {name: false, script: ''},

      //Exam questions stuff
      storedValues: {
        correctAnswerIndex: false,
        currentPropData: [],
      },

      defaultExamQuestions: [
        {
          label:  "ExamQuestion1a",
          english: {
            correct:  "Correct! Overall impression is that the patient is keenly responsive. Alert.",
            incorrect: "Incorrect. Overall impression is that the patient is keenly responsive. Alert.",
          },
          spanish: {
            correct:  "¡Correcto! La impresion general es que el paciente está responsivo. Alerta",
            incorrect: "Incorrecto. La impresión general es que el paciente está muy responsivo. Alerta"
          },
        },
        {
          label:  "ExamQuestion1b",
          english: {
            correct:  "Correct! Answers both questions correctly.",
            incorrect: "Incorrect. Answers both questions correctly.",
          },
          spanish: {
            correct:  "¡Correcto! Respondió ambas preguntas correctamente",
            incorrect: "Incorrecto. Responde ambas preguntas correctamente"
          },
        },
        {
          label:  "ExamQuestion1c",
          english: {
            correct:  "Correct! Performed both tasks correctly.",
            incorrect: "Incorrect. Performed both tasks correctly."
          },
          spanish: {
            correct:  "¡Correcto! Ejecutó ambas tareas correctamente",
            incorrect: "Incorrecto. Ejecuta ambas tareas correctamente"
          },
        },
        {
          label:  "ExamQuestion2",
          english: {
            correct:  "Correct! Normal.",
            incorrect: "Incorrect. Normal."
          },
          spanish: {
            correct:  "¡Correcto! Normal",
            incorrect: "Incorrecto. Normal"
          },
        },
        {
          label:   "ExamQuestion3",
          english: {
            correct: "Correct! No visual loss.",
            incorrect: "Incorrect. No visual loss."
          },
          spanish: {
            correct:  "¡Correcto! Sin pérdida visual",
            incorrect: "ncorrecto. Sin pérdida visual"
          },
        },
        {
          label:   "ExamQuestion4",
          english: {
            correct: "Correct! Flattened nasolabial fold, asymmetry on smiling. Minor paralysis.",
            incorrect: "Incorrect. Flattened nasolabial fold, asymmetry on smiling. Minor paralysis."
          },
          spanish: {
            correct:  "¡Correcto! Pliegue nasolabial aplanado, asimétrico al sonreír. Parálisis menor",
            incorrect: "Incorrecto. Pligue nasolabial aplanado, asimetría al sonreir. Parálisis menor"
          },
        },
        {
          label:   "ExamQuestion5a",
          english: {
            correct: "Correct! Limb cannot get to or maintain 90 (or 45) degrees, drifts down to bed, but has some effort against gravity.",
            incorrect: "Incorrect. Limb cannot get to or maintain 90 (or 45) degrees, drifts down to bed, but has some effort against gravity."
          },
          spanish: {
            correct:  "¡Correcto! La extremidad no puede mantenerse a 90 (ó 45) grados, cae a la cama pero tiene un poco de esfuerzo en contra la gravedad",
            incorrect: "Incorrecto. El miembro no puede mantenerse a 90 (ó 45) grados, desvío hacia la cama, pero presenta un poco de esfuerzo la gravedad"
          },
        },
        {
          label:    "ExamQuestion5b",
          english: {
            correct:  "Correct! Limb holds 90 (or 45) degrees for full 10 seconds. No drift.",
            incorrect: "Incorrect. Limb holds 90 (or 45) degrees for full 10 seconds. No drift."
          },
          spanish: {
            correct:  "¡Correcto! La extemidad se sotiene 90 (ó 45) grados durante 10 segundos. Sin desvío",
            incorrect: "Incorrecto. El miembro se sostiene 90 (ó 45) grados durante 10 segundos. Sin desvío"
          },
        },
        {
          label:    "ExamQuestion6a",
          english: {
            correct:  "Correct! Leg falls to bed immediately. No effort against gravity.",
            incorrect: "Incorrect. Leg falls to bed immediately. No effort against gravity."
          },
          spanish: {
            correct:  "¡Correcto! La pierna cae a la cama inmediatamente. Sin esfuerzo contra la gravedad",
            incorrect: "Incorrecto. La pierna cae a la cama inmediatamente. Sin esfuerzo contra la gravedad"
          },
        },
        {
          label:  "ExamQuestion6b",
          english: {
            correct:  "Correct! Leg holds 30-degree position for full 5 seconds. No drift.",
            incorrect: "Incorrect. Leg holds 30-degree position for full 5 seconds. No drift."
          },
          spanish: {
            correct:  "¡Correcto! La pierna se mantiene en posición de 30 grados durante 5 segundos. Sin desvío",
            incorrect: "Incorrecto. La pierna se sostiene en posición de 30 grados durante 5 segundos. Sin desvío"
          },
        },
        {
          label:  "ExamQuestion7",
          english: {
            correct:  "Correct! Present in one limb.",
            incorrect: "Incorrect. Present in one limb."
          },
          spanish: {
            correct:  "¡Correcto! Ausente",
            incorrect: "Incorrecto. Ausente"
          },
        },
        {
          label:  "ExamQuestion8",
          english: {
            correct:  "Correct! Patient feels pinprick is less sharp or dull on the affected side; or there is a loss of superficial pain with pinprick, but patient is aware of being touched. Mild-to-moderate sensory loss.",
            incorrect: "Incorrect. Patient feels pinprick is less sharp or dull on the affected side; or there is a loss of superficial pain with pinprick, but patient is aware of being touched. Mild-to-moderate sensory loss"
          },
          spanish: {
            correct:  "¡Correcto! El paciente siente que el pinchazo es menos agudo en el lado afectado; o har una pérdida o dolor superficial con el pinchazo, pero el paciente está conciente de ser tocado. Pérdida sensorial media a moderada",
            incorrect: "Incorrecto. El paciente siente que el pinchazo es menos agudo en el lado afectado; o har una pérdida o dolor superficial con el pinchazo, pero el paciente está conciente de ser tocado. Pérdida sensorial media a moderada"
          },
        },
        {
          label:  "ExamQuestion9",
          english: {
            correct:  "Correct! Normal. No aphasia.",
            incorrect: "Incorrect. Normal. No aphasia"
          },
          spanish: {
            correct:  "¡Correcto! Normal. Sin afasia",
            incorrect: "Incorrecto. Normal. Sin afasia"
          },
        },
        {
          label:  "ExamQuestion10",
          english: {
            correct:  "Correct! Normal.",
            incorrect: "Incorrect. Normal."
          },
          spanish: {
            correct:  "¡Correcto! Normal",
            incorrect: "Incorrecto. Normal"
          },
        },
        {
          label:  "ExamQuestion11",
          english: {
            correct:  "Correct! No abnormality.",
            incorrect: "Correct. No abnormality."
          },
          spanish: {
            correct:  "¡Correcto! Sin anormalidad",
            incorrect: "¡Correcto! Sin anormalidad"
          },
        },
      ],
    };
  },
  async created() {
    this.getModels();
    this.getTranslationData();
  },
  components: {
    OrbitSpinner,
    ClickToEdit
  },
  methods: {
    formatAnswer(lesson){
      lesson.answer = this.currentAnswer.id
    },
    filterChoices(lesson){
      if(!lesson.choices) return [];
      // Using map function to create a new array
      let choices = lesson.choices.map((choice, index) => ({
          id: index,  // Now the 'id' will be the index of the choice
          text: this.translationData[choice]
      }))
      return choices
    },
    storeQuestionChanges(){
      //find in this.modalData.blueprint the one that has Name === this.currentBlueprint.Name
      let index = this.modalData.blueprint.findIndex(question => question.Name == this.currentBlueprint.Name)

      this.currentBlueprint.answer = this.storedValues.correctAnswerIndex?.id
      //update data in currentBlueprint
      this.currentBlueprint.prop = this.storedValues.currentPropData
      //replace the blueprint with the new one
      this.modalData.blueprint[index] = this.currentBlueprint
      //close
      this.editCurrentQuestionModal = false
      this.activityToast('Stored')
    },
    activityToast(text) {
     this.$swal.fire({
        title: text, 
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showCloseButton: false,
        showCancelButton: false,
        cancelButtonColor: '#d33',
        confirmButtonText: 'View',
        confirmButtonColor: '#3085d6',
      })
    },
    EditCurrentAnswerData(rowData, modalData){
      //find in modalData.blueprint the one that has ".question" == this.currentRowData.question
      this.currentRowData = rowData;
      this.currentBlueprint = modalData.blueprint.find(question => question.question == this.currentRowData.question)

      this.storedValues.correctAnswerIndex = {
        id: this.currentBlueprint.answer,
        text: this.translationData[this.currentBlueprint.choices[this.currentBlueprint.answer]]
      }
      //check if this.storedValues.prop is a array string separated by , and if it is, convert it to an array
      if(this.currentBlueprint.prop.includes(',')){
        this.storedValues.currentPropData = this.currentBlueprint.prop.split(',')
      }else{
        this.storedValues.currentPropData = this.currentBlueprint.prop
      }
      this.editCurrentQuestionModal = true;
    },
    FormatExamQuestionData(currentExam, translationData) {
      let FormatedQuestionList = []
      for (const question of currentExam.blueprint) {
        FormatedQuestionList.push({
          name: `${question.question.replace('ExamQuestion', '')} - ${question.Name}` ,
          fullCorrectAnswer: translationData[question.choices[question.answer]] ,
          correctAnswer: translationData[question.choices[question.answer] + '_abbrv'] ,
          question: question.question,
        })
      }
      return FormatedQuestionList
    },
    getTranslationData() {
      const i8ln = firebase.firestore().collection('i18n').doc('English');
      i8ln.get().then((snapshot) => {
        this.translationData = snapshot.data();
      });
    },
    getModels() {
      this.models = [];

      const modelCollection = firebase.firestore().collection('model');

      const query = modelCollection.where('Deleted', '==', false);
      query.get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              const data = doc.data();

              data.id = doc.id;

              data.avatarImage ??= {};
              data.avatarImage.image ??= 'https://nihss.plus/assets/defaultProfilePic.png';

              data.FormattedCreatedDate = moment.unix(data.CreatedDate.seconds).format('LL');
              if (!data.leftOrRightArm) {
                data.leftOrRightArm = 'left';
              }
              if (!data.leftOrRightLeg) {
                data.leftOrRightLeg = 'left';
              }
              if (!data.ekg.hr) {
                data.ekg.hr = [60, 70];
              }


              if (!data.ekg.mmHg) {
                data.ekg.mmHg =
               {
                 systolic: [120, 140],
                 diastolic: [80, 100],
               };
              }

              if (!data.ekg.o2) {
                data.ekg.o2 = [95, 99];
              }
              if (!data.ekg.rr) {
                data.ekg.rr = [16, 20];
              }
              if (!data.ekg.temp) {
                data.ekg.temp = [97.8, 99.0];
              }

              if (!data.blueprint) {
                data.blueprint = emptyCourse;
              } else {
                data.blueprint.forEach((lesson)=>{
                  if (lesson.prop === '') {
                    lesson.prop = [];
                  } else {
                    lesson.prop = lesson.prop.split(',');
                  }
                });
              }

              this.models.push(data);
            });
          });
    },
    updatedField(lesson, key, value) {
      // create new key
      if (key === 'Response0' || key === 'Response1') {
        const newKey = key + '_' + this.modalData.name.replace(' ', '') + '_' + lesson.question;
        this.translationData[newKey] = value;
        this.modalData.blueprint[lesson.index].answers[lesson.answers.indexOf(key)] = newKey;
        this.createKey(newKey, value);
        this.$forceUpdate();
      } else {
        this.translationData[key] = value;
      }
    },
    /**
     * @author Vitor Hugo
     * @description Creates new keys in the translation database for the current model
     * @param {string} key
    */
    GenerateCollectionKeys(){
        let htmldesc = `
            <p>This will generate all the keys for the current model.</p>
            <br>
            All the keys will be generated in the current format: <br>
            <b>ResponseX_Name_ExamQuestionXX</b>
            <br>
            <br>
            <b>Example:</b> <br>
            Response0_Ed_P10_GR_01_ExamQuestion6b
            <br>
        `;
        let that = this;
        this.$swal.fire({
              title: 'Are you sure?',
              html : htmldesc,
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              showCancelButton: true,
              confirmButtonText: 'Yes, generate it!',
              showLoaderOnConfirm: true,
              preConfirm: (data) => {
                return new Promise(function(resolve, reject) {
                    // Generate keys to il8n collection
                    let ResponseEnglish = [];
                    let ResponseSpanish = [];
                    //itinerate defaultExamQuestions array
                    that.defaultExamQuestions.forEach((question)=>{
                    let Response0 = "Response0_" + that.modalData.name + "_" + question.label;
                    let Response1 = "Response1_" + that.modalData.name + "_" + question.label;
                    //english responses
                    ResponseEnglish = {
                      ...ResponseEnglish,
                      [Response0] : question.english.correct,
                      [Response1] : question.english.incorrect
                    }
                    //spanish responses
                    ResponseSpanish = {
                      ...ResponseSpanish,
                      [Response0] : question.spanish.correct,
                      [Response1] : question.spanish.incorrect
                    }
                  });
                  //insert into english and spanish collections
                  firebase.firestore().collection('i18n').doc('English').update(ResponseEnglish);
                  firebase.firestore().collection('i18n').doc('Spanish').update(ResponseSpanish);
                  //update current model
                  firebase.firestore().collection('model').doc(that.modalData.id).update({
                   HaveIl8nUpdated: true,
                  })
                  resolve({
                    status: 'success',
                  });
                });
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                this.$swal.fire({
                  title: 'Success!',
                  text: 'Keys generated successfully!',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                });
                this.editModelModal = false;
                this.getModels();
              }
            })
    },
    createKey(keyName, value) {
      const docData = {};

      docData[keyName] = value;
      // return firebase.firestore().collection('i18n').doc('English').set(docData, {merge: true});
    },
    async saveModel() {
      let admin = await firebase.auth().currentUser;
      // //Clear old possible logs
      LogCreator.clearLog();
      // //setup admin data
      LogCreator.setAdmin(admin)
      // //setup event data
      LogCreator.setEvent('update', this.modalData.id)

      const model = firebase.firestore().collection('model').doc(this.modalData.id);
      this.modalData.blueprint.forEach((lesson)=>{
        lesson.prop = lesson.prop.toString();
        lesson.answers.forEach((answer)=>{
          if (answer !== 'Response0' && answer !== 'Response1') {
            const tempData = {};
            tempData[answer] = this.translationData[answer];
            firebase.firestore().collection('i18n').doc('English').update(tempData);
          }
        });
      });
      var that = this;
      let _data = {}
      this.$swal.fire({
        title: 'Are you sure?',
        text : 'When clicking "Yes, update it!", the model will be updated, and the changes will be applied to all places where it is used.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: 'Yes, update it!',
        showLoaderOnConfirm: true,
        preConfirm: (data) => {
          return new Promise(function(resolve, reject) {
            var HaveDifferentNames = false;
            //searching direct on database, cause maybe the current model data isn't updated
            model.get().then((doc)=>{
              _data = doc.data();
              HaveDifferentNames = (_data.name != that.modalData.name || _data.patientName != that.modalData.patientName) ? true : false;
            }).then(()=> {
              //setup changes data
              LogCreator.setChanges(that.modalData, _data)
               //create log and assign Area
              let LogObject = LogCreator.create('3D Models', firebase.firestore.Timestamp.now())
              let notificationObject = LogCreator.createNotification('Editor')
              //save into firestore
              firebase.firestore().collection('HistoryLogs').add(LogObject)
              firebase.firestore().collection('Notifications').add(notificationObject)
              model.update({
                name: that.modalData.name,
                ...(HaveDifferentNames && {HaveIl8nUpdated: false}),
                patientName: that.modalData.patientName,
                ekg: that.modalData.ekg,
                avatarImage: that.modalData.avatarImage,
                script: that.modalData.script,
                group: that.modalData.group,
                evaluatorName: that.modalData.evaluatorName,
                bedposition: that.modalData.bedposition,
                animatorController: that.modalData.animatorController,
                monthFlag: that.modalData.monthFlag,
                Age: that.modalData.Age,
                room: that.modalData.room,
                leftOrRightArm: that.modalData.leftOrRightArm,
                leftOrRightLeg: that.modalData.leftOrRightLeg,
                blueprint: that.modalData.blueprint,
              }).then(() => {
                resolve({
                  status: 'success',
                  HaveDifferentNames: HaveDifferentNames
                });
              });
            }).catch((error)=>{
                reject(error);
            });
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
        }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire({
            icon: 'success',
            title: this.modalData.patientName + ' has been updated',
            text: 'The changes have been applied directly to the collection to user with name: ' + this.modalData.name,
            confirmButtonText: 'Ok',
          }).then(()=>{
            //check if names changed and if the il8n keys needs to be updated
            if(result.value.HaveDifferentNames){
              that.$swal.fire({
                    title: 'Warning!',
                    text: 'You have changed the model name or patient name, please check the il8n collection and create new keys if necessary.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
              }).then(()=>{
                that.editModelModal = false;
                that.getModels();
              });
            }else{
              that.editModelModal = false;
              that.getModels();
            }
          });
        }
      })
    },
    deleteData() {
      this.$swal.fire({
        title: 'Are you sure you want to delete <u>' + this.modalData.name + '</u>?',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          firebase.firestore().collection('model').doc(this.modalData.id).update({
            Active: false,
            Deleted: true,
            DeletedAt: firebase.firestore.Timestamp.now(),
          }).then(() => {
            this.editModelModal = false;
            this.getModels();
          });
        }
      });
    },
    changeArm(value) {
      const questionDataLeft = {
        'Name': 'Motor Arm (Left)',
        'question': 'ExamQuestion5a',
      };

      const questionDataRight = {
        'Name': 'Motor Arm (Right)',
        'question': 'ExamQuestion5b',
      };

      if (value === 'left') {
        for (const i in questionDataLeft) {
          if (questionDataLeft.hasOwnProperty(i)) {
            this.modalData.blueprint[6][i] = questionDataLeft[i];
            this.modalData.blueprint[7][i] = questionDataRight[i];
          }
        }
      }
      if (value === 'right') {
        for (const i in questionDataLeft) {
          if (questionDataLeft.hasOwnProperty(i)) {
            this.modalData.blueprint[6][i] = questionDataRight[i];
            this.modalData.blueprint[7][i] = questionDataLeft[i];
          }
        }
      }
    },
    changeLeg(value) {
      const questionDataLeft = {
        'Name': 'Motor Leg (Left)',
        'question': 'ExamQuestion6a',
      };

      const questionDataRight = {
        'Name': 'Motor Leg (Right)',
        'question': 'ExamQuestion6b',
      };

      if (value === 'left') {
        for (const i in questionDataLeft) {
          if (questionDataLeft.hasOwnProperty(i)) {
            this.modalData.blueprint[8][i] = questionDataLeft[i];
            this.modalData.blueprint[9][i] = questionDataRight[i];
          }
        }
      }
      if (value === 'right') {
        for (const i in questionDataLeft) {
          if (questionDataLeft.hasOwnProperty(i)) {
            this.modalData.blueprint[8][i] = questionDataRight[i];
            this.modalData.blueprint[9][i] = questionDataLeft[i];
          }
        }
      }
    },
    edit(model) {
      alert('Edit User: ' + JSON.stringify(model));
    },
    remove(model) {
      console.log(model);
    },
    handleEditorInitialization(editor) {
      this.editor = editor;
      this.$nextTick(() => {
        this.highlightSampleText();
      });
    },

    highlightSampleText() {
      const sampleText = document.getElementsByClassName('default-selection')[0];
      this.editor.selectElement(sampleText);
    },

    uploadModel(files) {
      if (files.length === 0) {
        return;
      }
      const reader = new FileReader();
      reader.readAsText(files[0]);
      reader.onload = (file) => {
        this.parsedModel = JSON.parse(file.target.result);
      };
    },
    async uploadOxygen(files) {
      if (files.length === 0) {
        return;
      }
      const ref = firebase.storage().ref().child('/oxygen/' + files[0].name);
      const imageURL = await ref.put(files[0]).then(async (snapshot) => {
        return snapshot.ref.getDownloadURL((url)=>{
          return url;
        });
      });
      this.uploadedO2 = [];
      this.modalData.ekg.oxygenRef = ref.fullPath;
      this.modalData.ekg.oxygenImage = imageURL;
    },
    async uploadRR(files) {
      if (files.length === 0) {
        return;
      }
      const ref = firebase.storage().ref().child('/rr/' + files[0].name);
      const imageURL = await ref.put(files[0]).then(async (snapshot) => {
        return snapshot.ref.getDownloadURL((url)=>{
          return url;
        });
      });
      this.uploadedRR = [];
      this.modalData.ekg.rrRef = ref.fullPath;
      this.modalData.ekg.rrImage = imageURL;
    },
    async uploadECG(files) {
      if (files.length === 0) {
        return;
      }
      const ref = firebase.storage().ref().child('/rhythms/' + files[0].name);
      const imageURL = await ref.put(files[0]).then(async (snapshot) => {
        return snapshot.ref.getDownloadURL((url)=>{
          return url;
        });
      });
      this.uploadedECG = [];
      this.modalData.ekg.imageRef = ref.fullPath;
      this.modalData.ekg.image = imageURL;
    },
    async uploadAvatarImage(files) {
      if (files.length === 0) {
        return;
      }
      const ref = firebase.storage().ref().child('/modelAvatars/' + files[0].name);
      const imageURL = await ref.put(files[0]).then(async (snapshot) => {
        return snapshot.ref.getDownloadURL((url)=>{
          return url;
        });
      });
      this.uploadedAvatarImage = [];
      this.modalData.avatarImage.imageRef = ref.fullPath;
      this.modalData.avatarImage.image = imageURL;
    },
    createModel() {
      this.uploadingNewModel = true;
      this.parsedModel.CreatedDate = firebase.firestore.Timestamp.now();
      this.parsedModel.Active = true;
      this.parsedModel.DeletedAt = null;
      this.parsedModel.Deleted = false;
      this.parsedModel.bedposition = '';
      this.parsedModel.Age = 70;
      this.parsedModel.room = 'ICU',
      this.parsedModel.ekg = {
        image: 'https://firebasestorage.googleapis.com/v0/b/nihss-bb52c.appspot.com/o/rhythms%2FnormalRhythm.png?alt=media&token=2c90ebb6-6434-4bfe-9f29-6ca342c8eddc',
        imageRef: 'rhythms/normalRhythm.png',
        rrRef: 'oxygen/YellowRange.png',
        rrImage: 'https://firebasestorage.googleapis.com/v0/b/nihss-bb52c.appspot.com/o/rr%2FYellowRange.png?alt=media&token=571d9e88-b9c1-4342-8a31-dbae44e526bf',
        oxygenRef: 'oxygen/BlueRange.png',
        oxygenImage: 'https://firebasestorage.googleapis.com/v0/b/nihss-bb52c.appspot.com/o/oxygen%2FBlueRange.png?alt=media&token=f4cf7a92-70c6-487a-8a6a-be64d8feb0e5',
        hr: [60, 70],
        mmHg: {
          systolic: [120, 140],
          diastolic: [80, 100],
        },
        o2: [95, 99],
        rr: [16, 20],
        temp: [97.8, 99.0],
      };

      firebase.firestore().collection('model').add(this.parsedModel).then(() => {
        this.uploadingNewModel = false;
        this.loading = false;
        this.uploadedModel = [];
        this.parsedModel = null;
        this.getModels();
      });
    },
  },
  watch: {

  },
  computed: {
    ExamQuestions(){
      return [
          {
            name: 'name',
            title: 'Question Name',
          },
          {
            name: '__slot:correctAnswer',
            title: 'Correct Answer',
          },{
            name: '__slot:actions',
            title: 'Actions',
          }
      ]
    },
    fields() {
      return [{
        name: 'name',
        title: 'Model Name',
      },
      {
        name: 'patientName',
        title: 'Patitent Name',
      },
      {
        name: 'group',
        title: 'Group Name',
      }, {
        name: 'FormattedCreatedDate',
        title: 'Created Date',
      }, {
        name: '__slot:actions',
      }];
    },
  },

};
</script>
<style>
.va-modal {
  width: 85%;
}

.textArea {
  background-color: rgb(245, 248, 249);
  width: 100%;
  height: 100%;
  min-height: 400px;
}
</style>
